import { combineReducers } from 'redux'
import UserReducer from './UserReducer'
import PhotoReducer from './PhotoReducer'
import PermissionReducer from './PermissionReducer'
import EcommerceReducer from './EcommerceReducer'
import ScrumBoardReducer from './ScrumBoardReducer'
import NavigationReducer from './NavigationReducer'
import NotificationReducer from './NotificationReducer'
import CheckInMemberReducer from './MemberCheckInReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    permissions: PermissionReducer,
    photos: PhotoReducer,
    users: UserReducer,
    membercheckin:CheckInMemberReducer
})

export default RootReducer
