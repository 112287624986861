import React, { useState } from 'react'

// App Components
import { API_URL, API_KEY } from 'app/config/constant'

// Import React FilePond
// import { FilePond, registerPlugin } from 'react-filepond'
import { FilePond } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const UploadFile = ({
    state,
    setState,
    maxUploadFile,
    propName = "files",
    prefixName: fileName,
    tartgetDirectory: directory,
}) => {
    const { prefix, v1, files: fileRoute } = API_KEY
    const uplaodFileRoute = `${API_URL}/${prefix}/${v1}/${fileRoute}`
    const [files, setFiles] = useState([])

    return (
        <div>
            <FilePond
                files={files}
                maxFiles={maxUploadFile}
                onupdatefiles={setFiles}
                allowMultiple={true}
                server={{
                    process: {
                        url: `${uplaodFileRoute}?tartgetDirectory=${directory}&prefixName=${fileName}`,
                        // headers: {
                        //     Authorization: `Bearer eyJH18ui0...`,
                        // },
                        onload: (response) => {
                            // props.onUploadComplete();
                            response = JSON.parse(response)
                            if (response.status === "SUCCESS") {
                                setState({
                                    ...state,
                                    [propName]: [
                                        {
                                            url: response?.data?.path
                                        },
                                        ...state[propName],
                                    ]
                                })
                            }
                        },
                    }
                }}
                name="upload_file"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
            />
        </div >
    )
}

export default UploadFile 
