// import { authRoles } from "./pages/auth/authRoles"

export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/public_holiday',
        icon: 'dashboard',
    },
    {
        label: 'Attendance',
        type: 'label',
    },
    {
        name: 'Check In/ Out',
        path: '/dashboard/checkinmember',
        icon: 'assignment_ind'
    },
    {
        name: 'Attendance Summary',
        path: '/dashboard/attendance_summary',
        icon: 'description'
    },
    {
        name: 'Calendar',
        path: '/dashboard/public_holiday',
        icon: 'event_note'
    },
    {
        name: 'Take Leave',
        path: '/dashboard/take_leave',
        icon: 'event'
    },
    {
        name: 'Trip Assignment',
        path: '/dashboard/trip_assignment',
        icon: 'directions_car'
    },
]
