// import axios from 'axios.js' // mock
import axios from '../config/api' // config
import jwtDecode from 'jwt-decode'
import React, { createContext, useEffect, useReducer } from 'react'
import ls from '../services/localStorageService'
import { MatxLoading } from 'app/pages/components'
import { API_KEY } from 'app/config/constant'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    member: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (currentMember, status) => {
    if (currentMember && status) {
        ls.setItem('status', status)
        ls.setItem('currentMember', currentMember)
        // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('currentMember')
        localStorage.removeItem('status')
        localStorage.removeItem('editMember')
        localStorage.removeItem('registerMember')
        localStorage.removeItem('authToken')
        // localStorage.removeItem('loginMemberCode')
        localStorage.removeItem('CheckIn')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, member } = action.payload
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                member,
            }
        }
        case 'LOGIN': {
            const { member } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                member,
            }
        }
        case 'LOGOUT': {
            console.log('LOGOOUT')
            return {
                ...state,
                isAuthenticated: false,
                member: null,
            }
        }
        case 'REGISTER': {
            const { member } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                member,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => {},
})

export const AuthProvider = ({ children }) => {
    const { prefix } = API_KEY
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (membercode, password) => {
        const response = await axios.post(`/${prefix}/membercode/login`, {
            membercode,
            password,
        })
        const data =
            response.data.status == 'success'
                ? response.data.data
                : response.data

        localStorage.setItem('Logout', false)
        setSession(data, response.data.status)
        dispatch({
            type: 'LOGIN',
            payload: {
                member: data,
            },
        })
        return response.data
    }

    const register = async ({ membercode, password, confirm_password }) => {
        const response = await axios.post(`${prefix}/membercode/register`, {
            membercode,
            password,
            confirm_password,
        })
        const { data } = response.data
        localStorage.setItem('Logout', false)
        dispatch({
            type: 'REGISTER',
            payload: {
                member: data,
            },
        })
    }

    const logout = () => {
        setSession(null, null)
        localStorage.setItem('Logout', true)
        localStorage.removeItem('editMember')
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const status = ls.getItem('status')
                const currentMember = ls.getItem('currentMember')
                if (currentMember) {
                    setSession(currentMember, status)
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            member: currentMember,
                        },
                    })
                } else {
                    console.log('test')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            member: null,
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        member: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                register,
                logout,
            }}
        >
            {children}{' '}
        </AuthContext.Provider>
    )
}

export default AuthContext
