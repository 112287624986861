import '../fake-db'
import React,{useEffect} from 'react'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { ConfirmProvider } from 'material-ui-confirm'
import { Store } from './redux/Store'
import { AllPages } from './routes/routes'
import { MatxTheme } from 'app/pages/components'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import useAuth from 'app/hooks/useAuth'
import ls from "./services/localStorageService"
import { useNavigate } from 'react-router-dom'
import { API_KEY } from 'app/config/constant'
import axios from 'app/config/api'
import Swal from 'sweetalert2'
const { prefix } = API_KEY
const loginMemberCode = ls.getItem('loginMemberCode') || {}
const schedule = require('node-schedule');
function getData(axios, constant_route) {
    return axios
        .get(constant_route)
        .then(function (response) {
            return response.data // the response.data is string of src
        })
        .catch(function (response) {
            console.log(response)
        })
}
const App = () => {
    const { logout } = useAuth()
    const navigate = useNavigate()
    const all_pages = useRoutes(AllPages());
     // Schedule the job to run every day at 11:55 PM
     const logoutJob = schedule.scheduleJob('55 23 * * *', function () {
        console.log('This job is supposed to clear localStorage!');
        logout();
        localStorage.removeItem('editMember');
        localStorage.removeItem('registerMember');
        localStorage.removeItem('loginMemberCode');
        localStorage.removeItem('authToken');
        localStorage.removeItem('CheckIn');
        localStorage.setItem('Logout', true)
        navigate("/session/signin");
    });

    // Schedule the job to run every 30 seconds
    const getMemberJob = schedule.scheduleJob('1 * * * * *', async function () {
        console.log('This job is supposed member suspended!');
        const currentMember = ls.getItem('currentMember') || {}
        const isLogout = ls.getItem('Logout')
        const member_data_route = `/${prefix}/memberno_info?filter=membercode,eq,${currentMember.membercode}`
        try {
            const memberData = await getData(axios, member_data_route);
            const status=memberData.memberno_info[0] && memberData.memberno_info[0].status
            console.log('status',status)
            console.log('isLogout',isLogout)
            if(!isLogout && status && status!='active'){
                Swal.fire({
                    icon: 'warning',
                    title: 'Member suspended!',
                    text: "Your account is suspended! Please contact to the administrator",
                    imageHeight: 100, 
                    imageWidth: 100,
                    confirmButtonColor: '#3085d6', 
                    confirmButtonText: 'OK',
                  }).then(async(result) => {
                    if (result.isConfirmed) {
                        logout();
                        localStorage.setItem('Logout', true)
                        localStorage.removeItem('editMember');
                        localStorage.removeItem('registerMember');
                        localStorage.removeItem('loginMemberCode');
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('CheckIn');
                        navigate("/session/signin");  
                    }
                  });
            }
            // Handle the member data as needed
        } catch (error) {
            console.error('Error fetching member data:', error);
        }
    });

    // Clear the scheduled jobs when the component unmounts
    useEffect(() => {
        return () => {
            if (logoutJob) {
                logoutJob.cancel();
            }
            if (getMemberJob) {
                getMemberJob.cancel();
            }
        };
    }, [logoutJob, getMemberJob]);
    return (
        <Provider store={Store}>
            <SettingsProvider>
                <MatxTheme>
                    <AuthProvider>
                        <ConfirmProvider>{all_pages}</ConfirmProvider>
                    </AuthProvider>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
