export const DoDispatchCase = (response, dispatch, types,) => {
    const { status, code, message, data } = response;

    if (["200", "201"].includes(code)) {
        dispatch({
            type: types.default,
            payload: { data },
        })
    }

    // update handler
    dispatch({
        type: types.handler,
        payload: { status, code, message },
    })
}