import React from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { useNavigate } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
    '& .closeButton': {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))

const DialogTitle = ((props) => {
    const { children, onClose } = props
    return (
        <DialogTitleRoot>
            <Typography variant="h4" align="center">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className='closeButton'
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitleRoot>
    )
})

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    "&.root": {
        padding: theme.spacing(2),
    },
}))

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
    justifyContent: 'center',
    '&.root': {
        margin: 0,
        padding: theme.spacing(1),
    },
}))

const MatxRangeDialog = ({
    title,
    sub_title,
    message,
    redUrl,
    setDistance,
}) => {
    const navigate = useNavigate()
    const handleConfirm = () => {
        setOpen(false)
        setDistance(false)
        console.log(redUrl)
        navigate(redUrl)
    }
    const [open, setOpen] = React.useState(true);
    return (
        <div>
            <Dialog
                onClose={() => setOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="md"
                // sx={{ paddingLeft:'250px'} }
                sx={isMobile?{ paddingLeft:'0px'}:{ paddingLeft:'250px'} }
            >
                <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => setOpen(false)}
                    >
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography 
                        sx={{
                            fontSize: 30,
                        }} 
                        align="center"
                        >{sub_title}</Typography>
                    <Typography gutterBottom align="center" color='red'>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        Retry
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MatxRangeDialog
