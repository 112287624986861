import axios from "../../config/api"
import { API_KEY } from "app/config/constant"

export const POST_NEW_CHECKIN_MEMBER = 'POST_NEW_CHECKIN_MEMBER'
export const GET_CHECKIN_MEMBER_LIST = 'GET_CHECKIN_MEMBER_LIST'
export const GET_CHECKIN_MEMBER_BY_ID = 'GET_CHECKIN_MEMBER_BY_ID'
export const PUT_CHECKIN_MEMBER_BY_ID = 'PUT_CHECKIN_MEMBER_BY_ID'
export const DEL_CHECKIN_MEMBER_BY_ID = 'DEL_CHECKIN_MEMBER_BY_ID'

const { prefix,membercheckin } = API_KEY
const constant_route = `/${prefix}/${membercheckin}`

export const postNewCheckinMember= (data,callback) => (dispatch) => {
    axios.post(`${constant_route}/createMember`, data).then((res) => {
        dispatch({
            type: POST_NEW_CHECKIN_MEMBER,
            payload: res.data,
        })
        callback(res.data)
    })
}

export const getCheckinMemberList = () => (dispatch) => {
    axios.get(constant_route).then((res) => {
        dispatch({
            type: GET_CHECKIN_MEMBER_LIST,
            payload: res.data,
        })
    })
}

export const getCheckinMemberById = (id) => (dispatch) => {
    axios.get(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: GET_CHECKIN_MEMBER_BY_ID,
            payload: res.data,
        })
        
    })
}

export const putCheckinMemberById = (data,callback) => (dispatch) => {
    axios.put(`${constant_route}/updateMember`, data).then((res) => {
        dispatch({
            type: PUT_CHECKIN_MEMBER_BY_ID,
            payload: res.data,
        })
        callback(res.data)
    })
}

export const delCheckinMemberById = (id) => (dispatch) => {
    axios.delete(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: DEL_CHECKIN_MEMBER_BY_ID,
            payload: res.data,
        })
    })
}