import axios from "../../config/api"
import { API_KEY } from "app/config/constant"
import { DoDispatchCase } from "./CommonActions"
import { HANDLE_RESPONSE_DATA } from "../reducers/ResponseReducer"
export const POST_NEW_USER = 'POST_NEW_USER'
export const GET_USER_LIST = 'GET_USER_LIST'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const PUT_USER_BY_ID = 'PUT_USER_BY_ID'
export const DEL_USER_BY_ID = 'DEL_USER_BY_ID'

const { prefix, v1, users } = API_KEY
const constant_route = `/${prefix}/${v1}/${users}`

export const postNewUser = (data) => (dispatch) => {
    axios.post(`${constant_route}/register`, data).then((res) => {
        dispatch({
            type: POST_NEW_USER,
            payload: res.data,
        })
    })
}

export const getUserList = () => (dispatch) => {
    axios.get(constant_route).then((res) => {
        dispatch({
            type: GET_USER_LIST,
            payload: res.data,
        })
    })
}

export const getUserById = (id) => (dispatch) => {
    axios.get(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: GET_USER_BY_ID,
            payload: res.data,
        })
    })
}

// export const putUserById = (data) => (dispatch) => {
//     axios.put(constant_route, data).then((res) => {
//         dispatch({
//             type: PUT_USER_BY_ID,
//             payload: res.data,
//         })
//     })
// }
export const putUserById = (data, callback) => (dispatch) => {
    axios.put(`/${prefix}/membercode/register`, data)
        .then((res) => {
            DoDispatchCase(res.data, dispatch, {
                default: PUT_USER_BY_ID,
                handler: HANDLE_RESPONSE_DATA
            })

            callback(res.data)
        })
        .catch((err) => {
            // can handle error relatively
            console.log("Error ", err)
        })
}

export const delUserById = (id) => (dispatch) => {
    axios.delete(`${constant_route}/${id}`).then((res) => {
        dispatch({
            type: DEL_USER_BY_ID,
            payload: res.data,
        })
    })
}