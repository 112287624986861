import React,{useState} from 'react';
import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Icon,
    Button,
    TableRow,
} from '@mui/material'
import { Box, styled } from '@mui/system';
//API
import axios from "../../../config/api"
import { API_KEY } from "app/config/constant"
import { TextField , AutoComplete } from "../../../utils/utils"
import ls from "../../../services/localStorageService";
//icon
import AddIcon from '@mui/icons-material/Add'
import {isMobile} from 'react-device-detect';

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
            },
        },
    },
}))
function AddDeleteTableRows(props){
    const { setTripMember } = props;
    const currentMember = ls.getItem('currentMember') || {}
    const [state, setState] = useState({
        rows:[{   
            membernoid:currentMember.id,
            membercode:currentMember.membercode,
            membername:currentMember.membername,
            remark:''
        }]
    });
    const [membercodes,setMembercodes]=useState([])
    
    
    const handleChange =  (idx,event) => {
        const { name, value } = event.target;
        const rows = [...state.rows];
        rows[idx] = {
            ...rows[idx],
            [name]: value
        };
        setState({
            rows
        });
    };
    const handleAddRow = () => {
        const item={
            membernoid:'',
            membercode:'',
            membername:'',
            remark:''
        }
        setState({rows: [...state.rows, item]})
      };
    const handleRemoveRow = (idx) => {
        const rows = [...state.rows]
        rows.splice(idx, 1)
        setState({ rows })
    }
    const handleAutoCompleteChange = (idx,event, newValue) => {
        if(newValue){
            const rows = [...state.rows];
            rows[idx] = {
                membernoid:newValue.id,
                membercode:newValue.membercode,
                membername:newValue.membername,
                remark:''
            };
            setState({
                rows
            });
        }else{
            const rows = [...state.rows];
            rows[idx] = {
                membernoid:'',
                membercode:'',
                membername:'',
                remark:''
            };
            setState({
                rows
            });
        }
    }
    React.useEffect(async () => {
        const { prefix } = API_KEY
        function getData(axios,constant_route) {
            return axios.get(constant_route)
            .then(function (response) {
                return response.data; // the response.data is string of src
            })
            .catch(function (response) {
                console.log(response);
            });
        }
        const membercode_route = `/${prefix}/membercode/hrMemberList`
        const memberCodes=await getData(axios,membercode_route)
        setMembercodes(memberCodes.memberno_info)
        // console.log(state)
        setTripMember(state)
    }, [state,setTripMember]);
    return (
        <Box width="100%" overflow="auto">
            <Paper style={{ width: 580 }}>
                <TableContainer style={{ width: 580 }}>
                    <StyledTable>
                        {isMobile&&(
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '100px'}}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleAddRow}
                                            size="small"
                                            startIcon={<AddIcon />}
                                        >
                                            Add
                                        </Button>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: '30px'}}>#</TableCell>
                                <TableCell style={{width: '100px'}}>Member No</TableCell>
                                <TableCell style={{width: '175px'}}>Member Name</TableCell>
                                <TableCell style={{width: '175px'}}>Remark</TableCell>
                                {!isMobile&&(    
                                    <TableCell style={{width: '100px'}}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleAddRow}
                                            size="small"
                                            startIcon={<AddIcon />}
                                        >
                                            Add
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state?.rows.map((sub, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">
                                        {index+1}
                                    </TableCell>
                                    <TableCell>
                                        <AutoComplete
                                            onChange={(event,value)=>(handleAutoCompleteChange(index,event,value))}
                                            options={membercodes || []}
                                            size="small"
                                            name="memberocde"
                                            defaultValue={{ membercode:sub.membercode || '' }}
                                            getOptionLabel={(option) => option.membercode}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="M.No *"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="text"
                                            size="small"
                                            name="membername"
                                            value={sub.membername}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="text"
                                            size="small"
                                            name="remark"
                                            onChange={(event)=>handleChange(index,event)}
                                            value={sub.remark}
                                            // validators={[]}
                                            // errorMessages={['this field is required']}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={()=>handleRemoveRow(index)} mb={2}>
                                            <Icon color="error" fontSize="medium">delete_forever</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
            </Paper>
        </Box>
    )
}
export default AddDeleteTableRows