import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {isMobile} from 'react-device-detect';
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import {TextField , SelectField} from "../../../utils/utils"
import { useNavigate } from 'react-router-dom'

import ls from "../../../services/localStorageService";
// MUI Components
import {
    Grid,
    Select,
    MenuItem,
} from '@mui/material';

import { JustifyBox } from "./style";

//API
import axios from "../../../config/api"
import { API_KEY } from "app/config/constant"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UpdateDialog = ({
    model_open,
    model_data
}) => {
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(model_open);
    const [leavecodes, setLeavecodes] = React.useState([]);
    const [noOfleave, setNoOfleave] = React.useState(0);
    const [remainLeave, setRemainLeave] = React.useState(0);
    const approved=model_data.approve_status==1?true:false
    const [takeOfleave, setTakeOfleave] = React.useState(model_data.takeNo);
    const [state, setState] = React.useState({
        id:model_data.id,
        fromdate:model_data.start,
        todate:model_data.end,
        leavetype:model_data.ltype+"-"+model_data.leavetitle,
        remark:model_data.remark
        })
    const [leaveTitle,setLeaveTitle]=React.useState(model_data.leavetitle)
    const [cannotLeave, setCannotLeave] = React.useState({
        status:false,
        message:''
    })
    //take leave only current month
    const [startDate, setStartDate] = React.useState(moment(new Date()).startOf('month').format('DD/MM/YYYY'));
    const [endDate, setEndDate] = React.useState(moment(new Date()).endOf('month').format('DD/MM/YYYY'));
    const currentMember = ls.getItem('currentMember') || {}
    const { prefix } = API_KEY
    const leavecode_route = `/${prefix}/leavecode_info`
    function getData(axios,constant_route) {
        return axios.get(constant_route)
        .then(function (response) {
            return response.data; // the response.data is string of src
        })
        .catch(function (response) {
            console.log(response);
        });
    }

    //take leave 
  function takeLeaveDay(axios,leave_post_route,data) {
    return axios.post(leave_post_route,data)
    .then(function (response) {
        return response.data; // the response.data is string of src
    })
    .catch(function (response) {
        console.log(response);
    });
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/')
  };
  // handle data
const handleChange = (event) => {
// event.persist()
    setState({
        ...state,
        [event.target.name]: event.target.value,
    })
}
const handleSelect =async (event) => {
    const short=event.target.value.split('-')[0];
    const long=event.target.value.split('-')[1];
    setLeaveTitle(long)
    // event.persist()
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
        const entitle_leave_route1 = `/${prefix}/entitleleave/noOfLeave?leavetypeid=${short}&membercode=${currentMember.membercode}`
        var noOfLeaves1=await getData(axios,entitle_leave_route1)
        setRemainLeave(noOfLeaves1.data.length>0?noOfLeaves1.data[0].remain_leave:0)
        noOfLeaves1=noOfLeaves1.data.length>0?noOfLeaves1.data[0].entitleleave:0
        setNoOfleave(noOfLeaves1)
    }
  // handle date
const handleFromDate = (key, date) => {
    setState({
        ...state,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    let date2 = moment(state.todate || model_data.end, 'YYYY-MM-DD');//change
    let date1 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD') || model_data.start;
    let daysDiff = date2.diff(date1, 'days');
    const tLeave=isNaN(daysDiff)?0:daysDiff+1
    setTakeOfleave(tLeave)

    const checkValid=remainLeave-tLeave
    if(checkValid<=0){
        setCannotLeave({
            status:true,
            message:"Can't leave"
        })
    }else{
        setCannotLeave({
            status:false,
            message:""
        })
    }
}
const handleToDate = (key, date) => {
    setState({
        ...state,
        [key]: moment(date).format("YYYY-MM-DD")
    })
    let date1 = moment(state.fromdate || model_data.start, 'YYYY-MM-DD');//change
    let date2 = moment(moment(date).format("YYYY-MM-DD"), 'YYYY-MM-DD') || model_data.end;
    let daysDiff = date2.diff(date1, 'days');
    const tLeave=isNaN(daysDiff)?0:daysDiff+1
    setTakeOfleave(tLeave)

    const checkValid=remainLeave-tLeave
    if(checkValid<=0){
        setCannotLeave({
            status:true,
            message:`You can't take leave greater than remain leave (${remainLeave} days)`
        })
    }else{
        setCannotLeave({
            status:false,
            message:""
        })
    }
}

const handleFrom = () => {

}
const handleSubmit = async () => {
    var leav_obj={
        ...state,
        membernoid:currentMember.id,
        membercode:currentMember.membercode,
        attendancemonth:state.fromdate.substring(0,7),
        noOfLeave:takeOfleave
    }

    //remove leave
    const remove_leave_route = `/${prefix}/entitleleave/removeLeaveDay?id=${state.id}&leavetype=${state.leavetype.split('-')[0]}&membercode=${currentMember.membercode}&noOfLeave=${takeOfleave}`
    var noOfLeaves=await getData(axios,remove_leave_route)
    console.log(noOfLeaves)
    //add leave
    // const leaveday_post_route = `/${prefix}/entitleleave/memberLeave`
    // const leave_day=await takeLeaveDay(axios,leaveday_post_route,leav_obj)
    setOpen(false);
    navigate('/')
}
const handleUpdate = async () => {
    var leav_obj={
        ...state,
        membernoid:currentMember.id,
        membercode:currentMember.membercode,
        attendancemonth:state.fromdate.substring(0,7),
        noOfLeave:takeOfleave
    }
    let mon=moment(leav_obj.fromdate).format("MM")
    mon=parseInt(mon)>=4?parseInt(mon)-4:parseInt(mon)+8;
    let nextDate=moment(leav_obj.todate, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');;
    let prevDate=moment(leav_obj.fromdate, "YYYY-MM-DD").subtract(1, 'days').format('YYYY-MM-DD');
    const checkHolidayQ=`/${prefix}/frontend/checkHolidayForLeave?month=${mon}&prevDate=${prevDate}&nextDate=${nextDate}&fromDate=${leav_obj.fromdate}&toDate=${leav_obj.todate}`
    let checkHoliday=await getData(axios,checkHolidayQ)
    checkHoliday=checkHoliday?.data?.data;

    const checkOffdayQ=`/${prefix}/frontend/checkOffdayDateForLeave?prevDate=${prevDate}&nextDate=${nextDate}&fromDate=${leav_obj.fromdate}&toDate=${leav_obj.todate}&membercode=${currentMember.membercode}`
    let checkOffday=await getData(axios,checkOffdayQ)
    checkOffday=checkOffday?.data?.data;
    console.log(checkHoliday)
    console.log(checkOffday)
    let message=''
    if(!checkOffday){
        message="Leave day can't be taken together with offday"
    }else if(!checkHoliday){
        message="Leave day can't be taken together with public holidays"
    }else{
        message=""
    }
    if(checkOffday&&checkHoliday){
    // add leave
        const leaveday_update_route = `/${prefix}/entitleleave/updateMemberLeave`
        const leave_day=await takeLeaveDay(axios,leaveday_update_route,leav_obj)
        setOpen(false);
        navigate('/')
    }else{
        setCannotLeave({
            status:true,
            message:message
        })
    }
}
// alert(open)
React.useEffect(async () => {
    const leaveCodes=await getData(axios,leavecode_route)

    // console.log(state)
    const entitle_leave_route = `/${prefix}/entitleleave/noOfLeave?leavetypeid=${state.leavetype.split('-')[0]}&membercode=${currentMember.membercode}`
    var noOfLeaves=await getData(axios,entitle_leave_route)
    setRemainLeave(noOfLeaves.data.length>0?noOfLeaves.data[0].remain_leave:0)
    noOfLeaves=noOfLeaves.data.length>0?noOfLeaves.data[0].entitleleave:0
    setNoOfleave(noOfLeaves)
    const updatedLeaveData = leaveCodes.leavecode_info.filter(item => item.leaveid !== "O");
    setLeavecodes(updatedLeaveData)
}, []);
// console.log(model_data)
  return (
    <div>
      {/* <Button 
        variant="contained"
        color="primary" onClick={handleClickOpen}>
        Take Leave
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // sx={{ paddingLeft:'250px'} }
        sx={isMobile?{ paddingLeft:'0px'}:{ paddingLeft:'250px'} }
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {approved?'Taking Leave Days':'Edit Leave Days'}
        </BootstrapDialogTitle>
        <DialogContent dividers >
            <ValidatorForm onSubmit={handleFrom}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            What Leave Will You Take?
                        </Typography>
                        <SelectField
                            type="select"
                            size="small"
                            name="leavetype"
                            value={state.leavetype || ''}
                            onChange={handleSelect}
                        >
                            <MenuItem value="">-- Select Leave Type --</MenuItem>
                            {
                                leavecodes && leavecodes?.map((leave) => {
                                    return (
                                        <MenuItem
                                            key={`${leave.leaveid}-${leave.description}`}
                                            value={`${leave.leaveid}-${leave.description}`}
                                        >
                                            {leave.description}
                                        </MenuItem>
                                    )
                                })
                            }
                        </SelectField>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography gutterBottom>
                            Entitle {leaveTitle} Days : 
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Typography gutterBottom>
                            Taking {leaveTitle} Days : 
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography 
                            sx={{
                                fontSize: 30,
                            }} 
                            color='red'
                            >{noOfleave}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <Typography 
                            sx={{
                                fontSize: 30,
                            }} 
                            color='red'
                            >{takeOfleave==0?0:-takeOfleave}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={state.fromdate || model_data.start}
                                onChange={(date) => handleFromDate('fromdate', date)}
                                minDate={startDate}
                                // maxDate={endDate}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        variant="outlined"
                                        id="mui-pickers-date"
                                        name="fromdate"
                                        label="From Date"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={ state.todate || model_data.end}
                                onChange={(date) => handleToDate('todate', date)}
                                minDate={startDate}
                                // maxDate={endDate}
                                renderInput={(props) => (
                                    <TextField
                                        {...props}
                                        size="small"
                                        variant="outlined"
                                        id="mui-pickers-date"
                                        name="todate"
                                        label="To Date"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {cannotLeave.status?
                        <Grid item xs={12}>
                            <Typography 
                                sx={{
                                    fontSize: 15,
                                    textAlign:'center'
                                }} 
                                color='red'
                            >
                                {cannotLeave.message}
                            </Typography>
                        </Grid>:''
                    }
                    <Grid item xs={12}>
                        <Typography gutterBottom>
                            Remark
                        </Typography>
                        <TextField
                            label="Remark *"
                            type="text"
                            size="small"
                            name="remark"
                            onChange={handleChange}
                            value={state.remark || ''}
                            // validators={[]}
                            // errorMessages={['this field is required']}
                        />
                    </Grid>
                </Grid>
            </ValidatorForm>
        </DialogContent>
        <JustifyBox>
            <DialogActions>
                {
                    !approved && (
                        <>
                        <Button 
                            variant="contained"
                            color="warning" 
                            disabled={cannotLeave.status}
                            onClick={handleSubmit}>
                            Remove
                        </Button>
                        <Button 
                            variant="contained"
                            color="primary" 
                            disabled={cannotLeave.status}
                            onClick={handleUpdate}>
                            Save
                        </Button>
                        </>
                    )
                }
                <Button 
                    variant="outlined" onClick={handleClose}>
                Cancel
                </Button>
            </DialogActions>
        </JustifyBox> 
      </BootstrapDialog>
    </div>
  )
}

export default UpdateDialog