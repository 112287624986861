import React from 'react'
import { useNavigate } from "react-router-dom";
import { Card, Icon, Fab } from '@mui/material'
import { styled, Box } from '@mui/system'

const CardRoot = styled(Card)(() => ({
    height: '100%',
    padding: '20px 24px',
    borderRadius: '4px',
    boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 
    0px 4px 5px 0px rgb(0 0 0 / 14%), 
    0px 1px 10px 0px rgb(0 0 0 / 12%)`
}))

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1.25rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: !subtitle && "16px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const SimpleCard = ({ children, title, subtitle, newUrl }) => {
    const navigate = useNavigate()

    const navigatePage = (newUrl) => {
        navigate(newUrl)
    }

    return (
        <CardRoot elevation={6}>
            <CardTitle subtitle={subtitle}>
                {title}
                {newUrl &&
                    <Box sx={{ float: "right" }}>
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="Add"
                            className="button"
                            onClick={() => navigatePage(newUrl)}
                        >
                            <Icon>add</Icon>
                        </Fab>
                    </Box>
                }

            </CardTitle>
            {
                subtitle && <Box sx={{ mb: 2 }}>{subtitle}</Box>
            }
            {children}
        </CardRoot>
    )
}

export default SimpleCard
