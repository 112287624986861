import React from 'react'
import { Alert, Snackbar } from '@mui/material'

const MatxSnackbar = ({
    open,
    message,
    handleClose,
    duration = 10000,
    severity = 'success',
    horizontal = 'center',
    vertical = 'center',
}) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
        >
            <Alert
                variant="filled"
                severity={severity}
                onClose={handleClose}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default MatxSnackbar
