import axios from 'axios'
import ls from '../services/localStorageService'
import { API_URL, AUTHS, SRVER_AUTH } from './constant'

const axiosInstance = axios.create({
    baseURL: `${API_URL}`,
})

// Step-2: Create token, request, response & error handlers

const requestToken = async (payload) => {
    return axios({
        url: `${API_URL}/jwt/token`,
        method: 'POST',
        auth: payload,
    })
}

const isTokenValid = (tokenTime) => {
    const currentTime = new Date()
    const diffInMinutes = (currentTime - new Date(tokenTime)) / (1000 * 60)
    return diffInMinutes < 30
}

const requestHandler = async (request) => {
    // Token will be dynamic so we can use any app-specific way to always
    // fetch the new token before making the call
    const currentUser = ls.getItem('currentUser')
    const getAuthToken = ls.getItem('authToken')
    // const currentRole = AUTHS.find(auth => auth.role === currentUser?.role)
    if (getAuthToken && isTokenValid(getAuthToken.time)) {
        request.headers.Authorization = `Bearer ${getAuthToken.token}`
        return request
    }
    const response = await requestToken({
        username: SRVER_AUTH.CREDENTIAL_USERNAME || 'default.n3t@gmail.com',
        password: SRVER_AUTH.CREDENTIAL_PASSWORD || '5up3rv1s0r.p455w0rd',
    })
    ls.setItem('authToken', {
        token: response.data.accessToken,
        time: new Date(),
    })
    request.headers.Authorization = `Bearer ${response.data.accessToken}`
    return request
}

axiosInstance.interceptors.request.use(
    (request) => {
        // return request
        return requestHandler(request)
    },
    (error) => {
        return Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
    }
)

export default axiosInstance
