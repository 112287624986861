import { Box, styled, useTheme } from '@mui/system'
import Typography from '@mui/material/Typography'
export const FlexBox = styled(Box)(() => ({
    fontSize: '1.5rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginTop: "16px",
    display: 'flex',
    alignItems: 'center',
}))

export const TypographyText = styled(Typography)(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    display: 'flex',
    justifyContent: 'center',
    color:'red'
}))
export const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

export const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
}))


export const CheckInRoot = styled(JustifyBox)(() => ({
    minHeight: '100% !important',
    '& .card': {
        maxWidth: 800,
        borderRadius: 12,
        margin: '1rem',
    },
}))